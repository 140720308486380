"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findIndex = exports.queryElement = exports.getAttributeValue = exports.getInnerText = exports.getInnerHTML = void 0;
exports.getInnerHTML = function (selector, defaultValue) {
    return document.querySelector(selector) ? document.querySelector(selector).innerHTML : defaultValue;
};
exports.getInnerText = function (selector, defaultValue) {
    return document.querySelector(selector) ? document.querySelector(selector).innerText : defaultValue;
};
exports.getAttributeValue = function (selector, attributeName, defaultValue) {
    var _a;
    return document.querySelector(selector) ? (_a = document.querySelector(selector)) === null || _a === void 0 ? void 0 : _a.getAttribute(attributeName) : defaultValue;
};
exports.queryElement = function (selector) {
    return document.querySelector(selector) ? document.querySelector(selector) : null;
};
exports.findIndex = function (elements, element) {
    return [].slice.call(elements).findIndex(function (s) { return s == element; });
};
