"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pageLoad = exports.clickButton = void 0;
function clickButton(action, target, params) {
    return {
        type: "CLICK_BUTTON",
        action: action,
        target: target,
        params: params,
    };
}
exports.clickButton = clickButton;
function pageLoad(action, target, params) {
    return {
        type: "PAGE_LOAD",
        action: action,
        target: target,
        params: params,
    };
}
exports.pageLoad = pageLoad;
