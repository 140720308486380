"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformActions = void 0;
var pageHelpers_1 = require("../helpers/pageHelpers");
var playgroundTransformActions_1 = require("./playgroundTransformActions");
var url = require("url");
var immutability_helper_1 = require("immutability-helper");
exports.transformActions = __assign({ getPageInfo: function (input, target, params) {
        return input;
    }, getCampaignInfo: function (input, target, params) {
        var u = pageHelpers_1.parseQuery(url.parse(window.location.href).query);
        var ec = {
            cid: u.cid,
            utm_medium: u.utm_medium,
            utm_source: u.utm_source,
            utm_campaign: u.utm_campaign,
            utm_term: u.utm_term,
            utm_content: u.utm_content,
        };
        return immutability_helper_1.default(input, {
            externalCampaign: { $set: ec }
        });
    } }, playgroundTransformActions_1.playgroundTransformActions);
