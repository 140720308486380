"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformActions = void 0;
var immutability_helper_1 = require("immutability-helper");
// test code
exports.transformActions = {
    getPageInfo: function (input, target, params) {
        return input;
    },
    getPaginationInfo: function (input, target, params) {
        var data = immutability_helper_1.default(input, {
            linkTracking: {
                $set: {
                    actionType: "pagination",
                    linkText: target.getAttribute('data-page-num').toString(),
                }
            }
        });
        return data;
    }
};
