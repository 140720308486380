"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectorsProduct = void 0;
exports.selectorsProduct = {
    productTitle: "h2.product-detail__content--title",
    productVariantTitle: "data-image-title",
    productVariant: "div.variant-item.product-detail__content--tagitems__item.active",
    shopDetails: "#tableList > ul > li.active",
    shopUrl: "#tableList > ul > li.active > a",
    locationFinderInput: "#pac-input",
    accordionProductInfoTitle: "#productInfo .show",
    productsListTagName: "ego-product-variant",
    productsListTitleAttribute: "title",
    productsListSkuAttribute: "sku",
    productsListPagePathAttribute: "page-path",
    productsListImageAttribute: "image",
    productsListThumbnailAttribute: "thumbnail",
    productStarIndex: ".review-block__stars > a > i",
    RetailerName: ".online-retailer-list__item-name",
    ActiveThumbnailImage: '.product-thumbs__item.active',
    carouselMobDot: 'ul.mobile-product-list-slider > ul.slick-dots > li > button',
    carouselMobSlideLi: 'ul.mobile-product-list-slider > div.slick-list > div.slick-track > li',
    productElucentTitle: ".product-detail__content--title"
};
