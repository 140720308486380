"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generalActions = void 0;
var generalTransformActions_1 = require("./generalTransformActions");
exports.generalActions = {
    searchClick: [generalTransformActions_1.transformActions.getPageInfo, generalTransformActions_1.transformActions.getSearchInfo],
    footerSocialIconClick: [generalTransformActions_1.transformActions.getPageInfo, generalTransformActions_1.transformActions.getSocialIconInfo],
    footerLinkClick: [generalTransformActions_1.transformActions.getPageInfo, generalTransformActions_1.transformActions.getFooterLinkInfo],
    brandSwitchClick: [generalTransformActions_1.transformActions.getPageInfo, generalTransformActions_1.transformActions.getBrandSwitchInfo],
    siteLogoClick: [generalTransformActions_1.transformActions.getPageInfo, generalTransformActions_1.transformActions.getSiteLogoInfo],
    storeLocatorClick: [generalTransformActions_1.transformActions.getPageInfo, generalTransformActions_1.transformActions.getStoreLocatorInfo],
    searchResultsUpdated: [generalTransformActions_1.transformActions.getPageInfo, generalTransformActions_1.transformActions.getSearchResults],
    discoverMoreClick: [generalTransformActions_1.transformActions.getPageInfo, generalTransformActions_1.transformActions.getDiscoverMoreInfo],
    locationFinderMutated: [generalTransformActions_1.transformActions.getPageInfo, generalTransformActions_1.transformActions.getLocationFinderDefaultValue],
    accordionPanelHeadingClick: [generalTransformActions_1.transformActions.getPageInfo, generalTransformActions_1.transformActions.getAccordionPanelInfo],
    loginButtonClick: [generalTransformActions_1.transformActions.getPageInfo, generalTransformActions_1.transformActions.getLoginInfo],
    genericCTAClick: [generalTransformActions_1.transformActions.getPageInfo, generalTransformActions_1.transformActions.getGenericBtnInfo],
    productListBtnClick: [generalTransformActions_1.transformActions.getPageInfo, generalTransformActions_1.transformActions.getProductTileInfo],
    siteCartClick: [generalTransformActions_1.transformActions.getPageInfo, generalTransformActions_1.transformActions.getSiteCartInfo],
    siteUserClick: [generalTransformActions_1.transformActions.getPageInfo, generalTransformActions_1.transformActions.getSiteUserInfo],
    siteSearchClick: [generalTransformActions_1.transformActions.getPageInfo, generalTransformActions_1.transformActions.getSiteSearchInfo],
    cartCheckoutClick: [generalTransformActions_1.transformActions.getPageInfo, generalTransformActions_1.transformActions.getCartCheckoutInfo],
    getCheckOutPageDetails: [generalTransformActions_1.transformActions.populateCheckoutPage],
    signInClick: [generalTransformActions_1.transformActions.getPageInfo],
    forgotPasswordClick: [generalTransformActions_1.transformActions.getPageInfo],
    createAccountClick: [generalTransformActions_1.transformActions.getPageInfo]
};
