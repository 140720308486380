"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.quizActions = void 0;
var quizTransformActions_1 = require("./quizTransformActions");
exports.quizActions = {
    pageLoad: [quizTransformActions_1.transformActions.getPageInfo],
    quizAnswerClick: [quizTransformActions_1.transformActions.getPageInfo, quizTransformActions_1.transformActions.getQuizQuestion, quizTransformActions_1.transformActions.getQuizAnswer],
    quizButtonClick: [quizTransformActions_1.transformActions.getPageInfo, quizTransformActions_1.transformActions.getQuizButton],
    recommendationStartClick: [quizTransformActions_1.transformActions.getPageInfo, quizTransformActions_1.transformActions.getStartButton],
    recommendationAnswerClick: [quizTransformActions_1.transformActions.getPageInfo, quizTransformActions_1.transformActions.getRecommendationQuestion, quizTransformActions_1.transformActions.getRecommendationsAnswer],
    recommendationButtonClick: [quizTransformActions_1.transformActions.getPageInfo, quizTransformActions_1.transformActions.getRecommendationsButton],
    recommendationBuyOnlineClick: [quizTransformActions_1.transformActions.getPageInfo, quizTransformActions_1.transformActions.getBuyNowButton],
    elucentQuizAnswerClick: [quizTransformActions_1.transformActions.getPageInfo, quizTransformActions_1.transformActions.getElucentQuestion, quizTransformActions_1.transformActions.getElucentQuizAnswer],
    elucentQuizButtonClick: [quizTransformActions_1.transformActions.getPageInfo, quizTransformActions_1.transformActions.getElucentQuizButton],
    elucentQuestionAnswerProductClick: [quizTransformActions_1.transformActions.getPageInfo, quizTransformActions_1.transformActions.getElucentResultProduct],
};
