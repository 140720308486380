"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformActions = void 0;
var playgroundTransformActions_1 = require("./playgroundTransformActions");
var logger_1 = require("../helpers/logger");
var immutability_helper_1 = require("immutability-helper");
exports.transformActions = __assign({ getPageInfo: function (input, target, params) {
        return input;
    }, getContentDetails: function (input, target, params) {
        var parentEl = target.parentElement.parentElement;
        var sectionTitle = parentEl.querySelector(".cmp-VeniaAccountInformationPage__accountInformationPage__lineItemsContainer .line-items-title").html();
        logger_1.logger.log("getSearchInfo ", sectionTitle);
        return immutability_helper_1.default(input, {
            linkTracking: {
                //linkText: { $set: id },
                intentType: { $set: "User Information Edit" },
                content: function (content) { return immutability_helper_1.default(content || {}, {
                    contentName: { $set: sectionTitle },
                    contentType: { $set: "title" },
                }); }
            }
        });
    }, getAccountInfo: function (input, target, params) {
        var parentEl = target.parentElement.parentElement;
        var sectionTitle = parentEl.querySelector(".cmp-VeniaAccountInformationPage__accountInformationPage__lineItemsContainer .line-items-title").html();
        logger_1.logger.log("getSearchInfo ", sectionTitle);
        return immutability_helper_1.default(input, {
            linkTracking: {
                //linkText: { $set: id },
                intentType: { $set: "User Information Edit" },
                content: function (content) { return immutability_helper_1.default(content || {}, {
                    contentName: { $set: sectionTitle },
                    contentType: { $set: "title" },
                }); }
            }
        });
    }, getManageAddressInfo: function (input, target, params) {
        var sectionTitle = $('.dashboard-address-title .title').html();
        logger_1.logger.log("getSearchInfo ", sectionTitle);
        var c = immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                intentType: { $set: "ManageAddress" },
                content: function (content) { return immutability_helper_1.default(content || {}, {
                    contentName: { $set: sectionTitle },
                    contentType: { $set: "title" },
                }); }
            }); }
        });
        return c;
    }, getEditAddressInfo: function (input, target, params) {
        var sectionTitle = $('.dashboard-address-title .title').html();
        logger_1.logger.log("getSearchInfo ", sectionTitle);
        var c = immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                intentType: { $set: "Edit" },
                content: function (content) { return immutability_helper_1.default(content || {}, {
                    contentName: { $set: sectionTitle },
                    contentType: { $set: "title" },
                }); }
            }); }
        });
        return c;
    }, getDeleteAddressInfo: function (input, target, params) {
        var sectionTitle = $('.dashboard-address-title .title').html();
        logger_1.logger.log("getSearchInfo ", sectionTitle);
        var c = immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                intentType: { $set: "Delete" },
                content: function (content) { return immutability_helper_1.default(content || {}, {
                    contentName: { $set: sectionTitle },
                    contentType: { $set: "title" },
                }); }
            }); }
        });
        return c;
    } }, playgroundTransformActions_1.playgroundTransformActions);
