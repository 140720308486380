"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformActions = void 0;
var querySelector_1 = require("../helpers/querySelector");
var immutability_helper_1 = require("immutability-helper");
exports.transformActions = {
    getPageInfo: function (input, target, params) {
        return input;
    },
    getRecommendationAnswer: function (input, target, params) {
        return recommendationAnswerInput(input, target);
    },
    getRecommendationButton: function (input, target, params) {
        return recommendationButtonInput(input, target);
    },
    getProductRecommendationProducts: function (input, target, params) {
        return productRecommendationProducts(input, target);
    }
};
function generateID(name) {
    return name.trim().toLowerCase().replace(/\s+/g, '-');
}
function recommendationAnswerInput(input, target) {
    var type = "Product Recommendation Tool", quizTitleElement = document.querySelector('.product-recommendation .quiz-container'), name = (quizTitleElement !== null) ? quizTitleElement.getAttribute('data-quiz-title') : null, id = name ? generateID(name) : null, questionIndex = (target !== null) ? target.getAttribute('data-ques-count') : null, questionTitle = querySelector_1.getInnerText('.product-recommendation  .quiz--step.active h4'), questionValue = querySelector_1.getInnerText('.product-recommendation .answer-container.selected div.answer-input'), actionType = "AnswerClicked";
    var recommendationObj = {
        type: type,
        name: name,
        id: id,
        questionIndex: questionIndex,
        questionTitle: questionTitle,
        questionValue: questionValue,
        actionType: actionType
    };
    return immutability_helper_1.default(input, {
        linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
            recommendation: { $set: recommendationObj },
        }); }
    });
}
// Function to get the maximum question count
function getLastQuestionIndex() {
    var answerInputs = document.querySelectorAll('.answer-container');
    return Array.from(answerInputs).reduce(function (max, input) {
        var quesCount = parseInt(input.getAttribute('data-ques-count'));
        return quesCount > max ? quesCount : max;
    }, 0);
}
var lastQuestionNextClicked = false; // Global flag to track final question click
function recommendationButtonInput(input, target) {
    var lastQuestionIndex = getLastQuestionIndex();
    // Find the active quiz step, then locate the child element with the data-ques-count attribute
    var activeStep = document.querySelector('.quiz--step.active .answer-container');
    var currentQuestionIndex = activeStep ? parseInt(activeStep.getAttribute('data-ques-count')) : null;
    // Get the button action text, either "Next" or "Submit"
    var actionType = target ? target.innerText : null;
    // Check if the user is on the last question
    if (currentQuestionIndex === lastQuestionIndex) {
        if (actionType === "SUBMIT" && lastQuestionNextClicked) {
            actionType = "SUBMIT"; // Final "Submit" action
        }
        else {
            actionType = "NEXT"; // First "Next" click on the last question
            lastQuestionNextClicked = true; // Set flag to indicate last question "Next" was clicked
        }
    }
    else {
        // Reset the flag if they navigate away from the last question
        lastQuestionNextClicked = false;
    }
    return immutability_helper_1.default(input, {
        linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
            recommendation: function (recommendation) { return immutability_helper_1.default(recommendation || {}, {
                actionType: { $set: actionType }
            }); }
        }); }
    });
}
function productRecommendationProducts(input, target) {
    var actionType = (target !== null) ? "productsManipulated" : null;
    var dataAttributes = target.find('li').map(function (index) {
        var card = $(this).find('.card');
        var priceElement = $(this).find('.price span');
        return {
            index: index++,
            productSku: card.data('sku') || null,
            productName: card.data('key') || null,
            productPrice: priceElement.length ? priceElement.text().trim() : null
        };
    }).get();
    var urlParams = new URLSearchParams(window.location.search);
    var code = urlParams.get('code');
    return immutability_helper_1.default(input, {
        linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
            recommendation: function (recommendation) { return immutability_helper_1.default(recommendation || {}, {
                productList: function (productList) { return immutability_helper_1.default(productList || {}, {
                    productListCode: { $set: code },
                    products: { $set: dataAttributes }
                }); }
            }); }
        }); }
    });
}
