"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformActions = void 0;
var querySelector_1 = require("../helpers/querySelector");
var playgroundTransformActions_1 = require("./playgroundTransformActions");
var logger_1 = require("../helpers/logger");
var immutability_helper_1 = require("immutability-helper");
var currStep = 1;
exports.transformActions = __assign({ getPageInfo: function (input, target, params) {
        return input;
    }, getQuizQuestion: function (input, target, params) {
        return quizQuestionInput(input);
    }, getQuizAnswer: function (input, target, params) {
        return quizAnswerInput(input, target);
    }, getQuizButton: function (input, target, params) {
        var steps = document.querySelectorAll('.quiz--step'), direction = target.getAttribute('class').includes("next") ? "NEXT" : "PREV";
        var step, currpage;
        if (direction == "PREV") {
            currStep--;
            step = steps[currStep - 1];
            currpage = currStep;
        }
        else {
            currStep++;
            step = steps[currStep - 2];
            currpage = currStep - 1;
        }
        logger_1.logger.log('getQuizButton step=', step);
        var checked = step.querySelector('.answer input:checked'), label = step.querySelector('.answer input:checked + label'), currQuestion = (step.querySelector('.question-title h4')).innerText, currAnswer = (checked !== null) ? label.innerText : "", id = (checked !== null) ? checked.getAttribute('id') : null, name = (checked !== null) ? checked.getAttribute('name') : null, value = (checked !== null) ? checked.getAttribute('value') : null, isCorrect = (checked !== null) ? checked.getAttribute('iscorrect') : null, dataQuestionValue = (checked !== null) ? checked.getAttribute('data-ques-val') : null, dataQuestionId = (checked !== null) ? checked.getAttribute('data-ques-id') : null, dataQuestionCount = (checked !== null) ? checked.getAttribute('data-ques-count') : null;
        var surveyObj = {
            step: currpage,
            question: currQuestion,
            wasAnswered: (checked !== null),
            answer: currAnswer,
            id: id,
            name: name,
            value: value,
            isCorrect: isCorrect,
            dataQuestionValue: dataQuestionValue,
            dataQuestionId: dataQuestionId,
            dataQuestionCount: dataQuestionCount,
            arrow: direction,
        };
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                survey: { $set: surveyObj },
            }); }
        });
    }, getStartButton: function (input, target, params) {
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                eventAction: { $set: "startClicked" }
            }); }
        });
    }, getRecommendationQuestion: function (input, target, params) {
        return recommendationQuestionInput(input);
    }, getRecommendationsAnswer: function (input, target, params) {
        return recommendationAnswerInput(input, target);
    }, getRecommendationsButton: function (input, target, params) {
        var steps = document.querySelectorAll('.recommendation--step'), direction = target.getAttribute('class').includes("next") ? "NEXT" : "PREV";
        var step, currpage;
        if (direction == "PREV") {
            currStep--;
            step = steps[currStep - 1];
            currpage = currStep;
        }
        else {
            currStep++;
            step = steps[currStep - 2];
            currpage = currStep - 1;
        }
        var checked = step.querySelector('.answer input:checked'), label = step.querySelector('.answer input:checked + label'), currQuestion = (step.querySelector('.question-title h4')).innerText, currAnswer = (checked !== null) ? label.innerText : "", id = (checked !== null) ? checked.getAttribute('id') : null, name = (checked !== null) ? checked.getAttribute('name') : null, dataTerminate = (checked !== null) ? checked.getAttribute('data-terminate') : null, dataTags = (checked !== null) ? checked.getAttribute('data-tags') : null;
        var surveyObj = {
            step: currpage,
            question: currQuestion,
            wasAnswered: (checked !== null),
            answer: currAnswer,
            id: id,
            name: name,
            dataTerminate: dataTerminate,
            dataTags: dataTags,
            arrow: direction,
        };
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                survey: { $set: surveyObj },
            }); }
        });
    }, getBuyNowButton: function (input, target, params) {
        var product = target.parentElement.parentElement, common = target.parentElement.querySelector('common-product-item'), id = common.querySelector('a.product-tile__item--link').getAttribute('data-tracking-id').replace('product-item-', ''), sku = product.querySelector('common-product-item').getAttribute("data-sku"), img = product.querySelector('.product-tile__item--image').style.backgroundImage.split('"')[1], name = product.querySelector('.product-tile__item--title').innerText, tags = product.querySelector('common-product-item').getAttribute("tags"), href = target.getAttribute('href'), btnLabel = target.innerText;
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                linkDestinationURL: { $set: href },
                linkText: { $set: btnLabel },
                product: function (product) { return immutability_helper_1.default(product || {}, {
                    id: { $set: id },
                    sku: { $set: sku },
                    thumbnailImage: { $set: img },
                    name: { $set: name },
                    tags: { $set: tags },
                }); }
            }); }
        });
    }, getElucentQuestion: function (input, target, params) {
        return elucentQuestionInput(input);
    }, getElucentQuizAnswer: function (input, target, params) {
        return elucentAnswerInput(input, target);
    }, getElucentQuizButton: function (input, target, params) {
        var steps = document.querySelectorAll('.product-selector--step'), direction = target.getAttribute('class').includes("next") ? "NEXT" : "PREV";
        var step, currpage;
        if (direction == "PREV") {
            currStep--;
            step = steps[currStep - 1];
            currpage = currStep;
        }
        else {
            currStep++;
            step = steps[currStep - 2];
            currpage = currStep - 1;
        }
        var checked = step.querySelector('.answer input:checked'), label = step.querySelector('.answer input:checked + label'), currQuestion = (step.querySelector('.question-title h4')).innerText, currAnswer = (checked !== null) ? label.innerText : "", id = (checked !== null) ? checked.getAttribute('id') : null, name = (checked !== null) ? checked.getAttribute('name') : null, value = (checked !== null) ? checked.getAttribute('value') : null, dataTerminate = (checked !== null) ? checked.getAttribute('data-terminate') : null, dataTags = (checked !== null) ? checked.getAttribute('data-tags') : null, dataRelatedProduct = (checked !== null) ? checked.getAttribute('data-relatedproduct') : null;
        var surveyObj = {
            step: currpage,
            question: currQuestion,
            wasAnswered: (checked !== null),
            answer: currAnswer,
            id: id,
            name: name,
            value: value,
            dataTerminate: dataTerminate,
            dataTags: dataTags,
            dataRelatedProduct: dataRelatedProduct,
            arrow: direction,
        };
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                survey: { $set: surveyObj },
            }); }
        });
    }, getElucentResultProduct: function (input, target, params) {
        var product = target.parentElement.parentElement.parentElement.parentElement, id = target.getAttribute('data-tracking-id'), dataKmt = target.getAttribute('data-kmt'), tags = target.getAttribute('tags'), href = target.getAttribute('href'), sku = product.querySelector('common-product-item').getAttribute("data-sku"), img = product.querySelector('.product-tile__item--image').style.backgroundImage.split('"')[1], name = product.querySelector('.product-tile__item--title').innerText;
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                linkDestinationURL: { $set: href },
                product: function (product) { return immutability_helper_1.default(product || {}, {
                    id: { $set: id },
                    sku: { $set: sku },
                    thumbnailImage: { $set: img },
                    name: { $set: name },
                    tags: { $set: tags },
                }); }
            }); }
        });
    } }, playgroundTransformActions_1.playgroundTransformActions);
function quizQuestionInput(input) {
    var question = querySelector_1.getInnerText('.quiz .quiz--step.active .question-title h4'), pagenum = parseInt(querySelector_1.getInnerText('.quiz .quiz--pagination li.quiz--page.active')), currTarget = document.querySelector('.quiz .quiz--step.active .answer input:checked');
    if (currTarget !== null) {
    }
    return immutability_helper_1.default(input, {
        linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
            survey: function (survey) { return immutability_helper_1.default(survey || {}, {
                step: { $set: pagenum },
                question: { $set: question },
                wasAnswered: { $set: false }
            }); },
            componentName: { $set: "Quiz" }
        }); }
    });
}
function quizAnswerInput(input, target) {
    var question = querySelector_1.getInnerText('.quiz .quiz--step.active .question-title h4'), pagenum = parseInt(querySelector_1.getInnerText('.quiz .quiz--pagination li.quiz--page.active')), answer = querySelector_1.getInnerText('.quiz .quiz--step.active .answer input:checked + label'), id = (target !== null) ? target.getAttribute('id') : null, name = (target !== null) ? target.getAttribute('name') : null, value = (target !== null) ? target.getAttribute('value') : null, isCorrect = (target !== null) ? target.getAttribute('iscorrect') : null, dataQuestionValue = (target !== null) ? target.getAttribute('data-ques-val') : null, dataQuestionId = (target !== null) ? target.getAttribute('data-ques-id') : null, dataQuestionCount = (target !== null) ? target.getAttribute('data-ques-count') : null;
    return immutability_helper_1.default(input, {
        linkTracking: {
            survey: {
                answer: { $set: answer },
                id: { $set: id },
                name: { $set: name },
                value: { $set: value },
                isCorrect: { $set: isCorrect },
                dataQuestionValue: { $set: dataQuestionValue },
                dataQuestionId: { $set: dataQuestionId },
                dataQuestionCount: { $set: dataQuestionCount },
                wasAnswered: { $set: true }
            }
        }
    });
}
function recommendationQuestionInput(input) {
    var question = querySelector_1.getInnerText('.recommendation .recommendation--step.active .question-title h4'), pagenum = parseInt(querySelector_1.getInnerText('.recommendation .recommendation--pagination li.recommendation--page.active')), currTarget = document.querySelector('.recommendation .recommendation--step.active .answer input:checked');
    if (currTarget !== null) {
    }
    return immutability_helper_1.default(input, {
        linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
            survey: function (survey) { return immutability_helper_1.default(survey || {}, {
                step: { $set: pagenum },
                question: { $set: question },
                wasAnswered: { $set: false }
            }); },
            componentName: { $set: "Product Recommendation" }
        }); }
    });
}
function recommendationAnswerInput(input, target) {
    var answer = querySelector_1.getInnerText('.recommendation .recommendation--step.active .answer input:checked + label'), id = (target !== null) ? target.getAttribute('id') : null, name = (target !== null) ? target.getAttribute('name') : null, dataTerminate = (target !== null) ? target.getAttribute('data-terminate') : null, dataTags = (target !== null) ? target.getAttribute('data-tags') : null;
    return immutability_helper_1.default(input, {
        linkTracking: {
            survey: {
                answer: { $set: answer },
                id: { $set: id },
                name: { $set: name },
                dataTerminate: { $set: dataTerminate },
                dataTags: { $set: dataTags },
                wasAnswered: { $set: true }
            }
        }
    });
}
function elucentQuestionInput(input) {
    var question = querySelector_1.getInnerText('.product-selector .product-selector--step.active .question-title h4'), pagenum = parseInt(querySelector_1.getInnerText('.product-selector .product-selector--pagination li.product-selector--page.active')), currTarget = document.querySelector('.product-selector .product-selector--step.active .answer input:checked');
    if (currTarget !== null) {
    }
    return immutability_helper_1.default(input, {
        linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
            survey: function (survey) { return immutability_helper_1.default(survey || {}, {
                step: { $set: pagenum },
                question: { $set: question },
                wasAnswered: { $set: false }
            }); },
            componentName: { $set: "Elucent Recommendation" }
        }); }
    });
}
function elucentAnswerInput(input, target) {
    var question = querySelector_1.getInnerText('.product-selector .product-selector--step.active .question-title h4'), pagenum = parseInt(querySelector_1.getInnerText('.product-selector .product-selector--pagination li.product-selector--page.active')), answer = querySelector_1.getInnerText('.product-selector .product-selector--step.active .answer input:checked + label'), id = (target !== null) ? target.getAttribute('id') : null, name = (target !== null) ? target.getAttribute('name') : null, value = (target !== null) ? target.getAttribute('value') : null, dataTerminate = (target !== null) ? target.getAttribute('data-terminate') : null, dataTags = (target !== null) ? target.getAttribute('data-tags') : null, dataRelatedProduct = (target !== null) ? target.getAttribute('data-relatedproduct') : null;
    return immutability_helper_1.default(input, {
        linkTracking: {
            survey: {
                answer: { $set: answer },
                id: { $set: id },
                name: { $set: name },
                value: { $set: value },
                dataTerminate: { $set: dataTerminate },
                dataTags: { $set: dataTags },
                dataRelatedProduct: { $set: dataRelatedProduct },
                wasAnswered: { $set: true }
            }
        }
    });
}
