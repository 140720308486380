"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformActions = void 0;
var playgroundTransformActions_1 = require("./playgroundTransformActions");
var querySelector_1 = require("../helpers/querySelector");
var immutability_helper_1 = require("immutability-helper");
var logger_1 = require("../helpers/logger");
var selectorsProduct_1 = require("../consts/selectors/selectorsProduct");
exports.transformActions = __assign({ getPageInfo: function (input, target, params) {
        return input;
    }, getProductGuideInfo: function (input, target, params) {
        var productTitle = querySelector_1.getInnerText(selectorsProduct_1.selectorsProduct.productTitle, ""), productVariant = getVariant(), productSku = getProductSku(productVariant);
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                product: function (product) { return immutability_helper_1.default(product || {}, {
                    name: { $set: productTitle },
                    variant: { $set: productVariant },
                    sku: { $set: productSku }
                }); }
            }); }
        });
    }, getPdfInfo: function (input, target, params) {
        var pdfTitle = target.parentElement.querySelector(".asset-tile__item--title").innerText, pdfLink = target.getAttribute("href");
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                linkText: { $set: pdfTitle },
                linkDestinationURL: { $set: pdfLink }
            }); }
        });
    }, updateFormSuccess: function (input, target, params) {
        var errorArray = document.querySelectorAll("#updateuserprofile div.has-error"), success = errorArray.length > 0 ? "error" : "success", action = success;
        errorArray.forEach(function (oEl) {
            action = action + "|" + oEl.querySelector("span").innerText;
        });
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                linkText: { $set: "Update" },
                eventAction: { $set: action },
                eventCategory: { $set: "Update Profile" }
            }); }
        });
    } }, playgroundTransformActions_1.playgroundTransformActions);
var buildProductList = function () {
    var _a;
    var productsList = document.getElementsByTagName(selectorsProduct_1.selectorsProduct.productsListTagName), l = productsList.length, productSkuList = [];
    for (var i = 0; i < l; i++) {
        productSkuList.push({
            title: productsList[i].getAttribute(selectorsProduct_1.selectorsProduct.productsListTitleAttribute),
            sku: (_a = productsList[i].getAttribute(selectorsProduct_1.selectorsProduct.productsListSkuAttribute)) === null || _a === void 0 ? void 0 : _a.replace(" ", ""),
            path: productsList[i].getAttribute(selectorsProduct_1.selectorsProduct.productsListPagePathAttribute),
            image: productsList[i].getAttribute(selectorsProduct_1.selectorsProduct.productsListImageAttribute),
            thumbnail: productsList[i].getAttribute(selectorsProduct_1.selectorsProduct.productsListThumbnailAttribute),
        });
    }
    return productSkuList;
};
var getVariant = function (target) {
    return target ? target.innerText : querySelector_1.getInnerText(selectorsProduct_1.selectorsProduct.productVariant);
};
var getProductSku = function (variant) {
    var p = buildProductList();
    function checkSku(s) {
        logger_1.logger.log("sku=", s.sku, "variant=", variant);
        return s.title == variant;
    }
    var oEl = p.find(checkSku);
    return oEl === null || oEl === void 0 ? void 0 : oEl.sku;
};
