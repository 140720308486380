"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var actions_1 = require("./state/appState/actions");
var initStore_1 = require("./initStore");
var debugTools_1 = require("./helpers/debugTools");
var logger_1 = require("./helpers/logger");
window.he = {
    dispatch: function (action, target, params) {
        logger_1.logger.log("he:targetElement", target, params);
        initStore_1.store.dispatch(action == "pageLoad" ? actions_1.pageLoad(action, target, params) : actions_1.clickButton(action, target, params));
    },
};
if (debugTools_1.isDebug) {
    window.he.debug = debugTools_1.debugTools;
}
window.digitalData = initStore_1.digitalData;
// console.log('CJS injector from Webpack loaded');
