"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformActions = void 0;
var querySelector_1 = require("../helpers/querySelector");
var playgroundTransformActions_1 = require("./playgroundTransformActions");
var logger_1 = require("../helpers/logger");
var immutability_helper_1 = require("immutability-helper");
var pageHelpers_1 = require("../helpers/pageHelpers");
var selectorsProduct_1 = require("../consts/selectors/selectorsProduct");
exports.transformActions = __assign({ getPageInfo: function (input, target, params) {
        return input;
    }, getSearchInfo: function (input, target, params) {
        var parentEl = target.parentElement;
        var inputEl = parentEl.querySelector("input.cmp-search__input");
        var term = inputEl.value;
        var action = parentEl.parentElement.getAttribute("action");
        logger_1.logger.log("getSearchInfo ", term);
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                internalSearch: function (internalSearch) { return immutability_helper_1.default(internalSearch || {}, {
                    event: { $set: "internal-search" },
                    searchTerm: { $set: term },
                }); }
            }); }
        });
    }, getSearchResults: function (input, target, params) {
        var numResults = target.querySelectorAll("li.result-item").length;
        var noResults = (numResults == 0);
        var term = (pageHelpers_1.parseQuery(window.location.search));
        logger_1.logger.log("getSearchResults ", target, numResults, noResults, term);
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                internalSearch: function (internalSearch) { return immutability_helper_1.default(internalSearch || {}, {
                    event: { $set: "search-results" },
                    searchTerm: { $set: term.fulltext },
                    numResults: { $set: numResults },
                    noResults: { $set: noResults },
                }); }
            }); }
        });
    }, getSocialIconInfo: function (input, target, params) {
        var _a;
        var name = target.querySelector('img').getAttribute('src');
        var link = target.getAttribute('href');
        var id = (_a = (target.getAttribute('data-tracking-id'))) !== null && _a !== void 0 ? _a : link.split(".")[1];
        logger_1.logger.log("getSocialIconInfo target=", target, "name=", name, "link=", link, "id=", id);
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                socialChannel: { $set: id },
                content: function (content) { return immutability_helper_1.default(content || {}, {
                    contentId: { $set: id },
                    contentName: { $set: name },
                    contentLink: { $set: link },
                    contentType: { $set: "footer social icon" },
                }); }
            }); }
        });
    }, getFooterLinkInfo: function (input, target, params) {
        var _a;
        var id = (_a = (target.getAttribute('data-tracking-id'))) !== null && _a !== void 0 ? _a : "NA";
        var name = target.innerText;
        var link = target.getAttribute('href');
        logger_1.logger.log("getFooterLinkInfo target=", target, "name=", name, "link=", link, "id=", id);
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                socialChannel: { $set: id },
                content: function (content) { return immutability_helper_1.default(content || {}, {
                    contentId: { $set: id },
                    contentName: { $set: name },
                    contentLink: { $set: link },
                    contentType: { $set: "footer link" },
                }); }
            }); }
        });
    }, getBrandSwitchInfo: function (input, target, params) {
        var _a;
        var id = (_a = (target.getAttribute('data-tracking-id'))) !== null && _a !== void 0 ? _a : "NA";
        var name = target.querySelector('img').getAttribute('src');
        var link = target.getAttribute('href');
        logger_1.logger.log("getBrandSwitchInfo target=", target, "name=", name, "link=", link, "id=", id);
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                socialChannel: { $set: id },
                content: function (content) { return immutability_helper_1.default(content || {}, {
                    contentId: { $set: id },
                    contentName: { $set: name },
                    contentLink: { $set: link },
                    contentType: { $set: "brand switch logo" },
                }); }
            }); }
        });
    }, getSiteLogoInfo: function (input, target, params) {
        var _a;
        var id = (_a = (target.getAttribute('data-tracking-id'))) !== null && _a !== void 0 ? _a : "NA";
        var name = target.querySelector('img').getAttribute('src');
        var link = target.getAttribute('href');
        logger_1.logger.log("getSiteLogoInfo target=", target, "name=", name, "link=", link, "id=", id);
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                socialChannel: { $set: id },
                content: function (content) { return immutability_helper_1.default(content || {}, {
                    contentId: { $set: id },
                    contentName: { $set: name },
                    contentLink: { $set: link },
                    contentType: { $set: "site logo click" },
                }); }
            }); }
        });
    }, getUserSearchCart: function (input, target, params) {
    }, getStoreLocatorInfo: function (input, target, params) {
        var _a;
        var id = (_a = (target.getAttribute('data-tracking-id'))) !== null && _a !== void 0 ? _a : "NA", linkText = target.querySelector('.wheretobuy--link__text').innerText, linkURL = target.getAttribute('href'), eventCategory = "Store Locator";
        logger_1.logger.log("getStoreLocatordInfo = ", target, "id=", id);
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                linkText: { $set: linkText },
                linkDestinationURL: { $set: linkURL },
                eventCategory: { $set: eventCategory }
            }); }
        });
    }, getDiscoverMoreInfo: function (input, target, params) {
        var _a;
        var id = (_a = (target.getAttribute('data-tracking-id'))) !== null && _a !== void 0 ? _a : "NA", linkText = target.innerText, linkURL = target.getAttribute('href'), eventCategory = "Discover More Btn";
        logger_1.logger.log("getStoreLocatordInfo = ", target, "id=", id);
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                linkText: { $set: linkText },
                linkDestinationURL: { $set: linkURL },
                eventCategory: { $set: eventCategory }
            }); }
        });
    }, getLocationFinderDefaultValue: function (input, target, params) {
        var internalSearchObj = { searchTerm: getStoreSearchTerm(), };
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                internalSearch: { $set: internalSearchObj }
            }); }
        });
    }, getAccordionPanelInfo: function (input, target, params) {
        var name = target.querySelector("h4.panel-title").innerText, id = target.getAttribute("id"), dataAriaExpanded = target.getAttribute("aria-expanded"), state = (dataAriaExpanded == "true") ? "expanded" : "collapsed", dataTarget = target.getAttribute("data-target"), accordionObj = {
            id: id,
            panelName: name,
            state: state,
            dataTarget: dataTarget
        };
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                accordion: { $set: accordionObj }
            }); }
        });
    }, getLoginInfo: function (input, target, params) {
        var url = target.parentElement.querySelector('input[id="url"]').getAttribute("value"), redirectUrl = target.parentElement.querySelector('input[id="redirectUrl"]').getAttribute("value"), linkText = target.innerText, eventCategory = "Login Button Submit";
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                linkText: { $set: linkText },
                linkDestinationURL: { $set: redirectUrl },
                eventCategory: { $set: eventCategory }
            }); }
        });
    }, getGenericBtnInfo: function (input, target, params) {
        var _a;
        var url = target.getAttribute("url"), btnText = target.innerText, eventCategory = "Generic Button Click";
        var data_title = (_a = (target.getAttribute('data-tracking-id'))) !== null && _a !== void 0 ? _a : "";
        var title = "";
        var linkType = "";
        if (data_title.length > 1) {
            var parts = data_title.split(':');
            title = parts.length > 1 ? parts[1].trim() : '';
            linkType = parts[0];
        }
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                linkText: { $set: btnText },
                linkDestinationURL: { $set: url },
                eventCategory: { $set: eventCategory },
                linkType: { $set: linkType },
                contentTile: { $set: title }
            }); }
        });
    }, getProductTileInfo: function (t, e, n) {
        var r, i, o, a, u = e.closest(".product-tile"), c = u.querySelector("common-product-item"), l = null === (r = null == c ? void 0 : c.querySelector("div.product-tile__links > a.product-tile__item--btn")) || void 0 === r ? void 0 : r.getAttribute("data-tracking-id").replace("product-item-", ""), f = null == c ? void 0 : c.getAttribute("data-sku"), d = null == c ? void 0 : c.getAttribute("tags"), p = null === (i = null == c ? void 0 : c.querySelector(".product-tile__item--image")) || void 0 === i ? void 0 : i.style.backgroundImage.split('"')[1], h = null === (o = null == c ? void 0 : c.querySelector(".product-tile__item--title")) || void 0 === o ? void 0 : o.innerText, g = null == u ? void 0 : u.querySelector(".product-tile__item--btn"), m = null === (a = null == u ? void 0 : u.querySelector("a")) || void 0 === a ? void 0 : a.getAttribute("href"), v = null == g ? void 0 : g.getAttribute('data-link-name');
        return immutability_helper_1.default(t, {
            linkTracking: function (t) {
                return immutability_helper_1.default(t || {}, {
                    linkDestinationURL: { $set: m },
                    linkText: { $set: v },
                    linkType: { $set: "" },
                    actionType: { $set: "" },
                    product: function (t) {
                        return immutability_helper_1.default(t || {}, { id: { $set: l }, sku: { $set: f }, thumbnailImage: { $set: p }, name: { $set: h }, tags: { $set: d } });
                    },
                });
            },
        });
    }, getSiteCartInfo: function (input, target, params) {
        var eventCategory = "Cart Icon Click";
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                eventCategory: { $set: eventCategory }
            }); }
        });
    }, getSiteUserInfo: function (input, target, params) {
        var eventCategory = "User Icon Click";
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                eventCategory: { $set: eventCategory }
            }); }
        });
    }, getSiteSearchInfo: function (input, target, params) {
        var eventCategory = "Search Icon Click";
        var tmp = immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                eventCategory: { $set: eventCategory }
            }); }
        });
        //console.log(" --- Search Icon --- ")
        //console.log(tmp)
        return tmp;
    }, getCartCheckoutInfo: function (input, target, params) {
        var eventCategory = "Cart Button Click";
        var tmp = immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                eventCategory: { $set: eventCategory }
            }); }
        });
        //console.log(" --- Search Icon --- ")
        //console.log(tmp)
        return tmp;
    }, populateCheckoutPage: function (input, target, params) {
        var eventCategory = "Cart Page Loaded";
        var tmp = immutability_helper_1.default(input, {
            checkoutCart: function (checkoutCart) { return immutability_helper_1.default(checkoutCart || {}, {
                event: { $set: eventCategory },
                products: { $set: params.products },
                price: { $set: params.price }
            }); }
        });
        //console.log(" --- Search Icon --- ")
        //console.log(tmp)
        return tmp;
    }, getSignInInfo: function (input, target, params) {
        var eventCategory = "SignIn Button Click";
        var tmp = immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                eventCategory: { $set: eventCategory }
            }); }
        });
        //console.log(" --- SignIn Button Click --- ")
        //console.log(tmp)
        return tmp;
    }, getForgotPasswordInfo: function (input, target, params) {
        var eventCategory = "ForgotPassword Button Click";
        var tmp = immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                eventCategory: { $set: eventCategory }
            }); }
        });
        //console.log(" --- ForgotPassword Button Click --- ")
        //console.log(tmp)
        return tmp;
    }, getCreateAccountInfo: function (input, target, params) {
        var eventCategory = "Create Account Button Click";
        var tmp = immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                eventCategory: { $set: eventCategory }
            }); }
        });
        //console.log(" --- Create Account Button Click --- ")
        //console.log(tmp)
        return tmp;
    } }, playgroundTransformActions_1.playgroundTransformActions);
var getStoreSearchTerm = function () {
    var oEl = querySelector_1.queryElement(selectorsProduct_1.selectorsProduct.locationFinderInput);
    return (oEl != null) ? oEl.value : "";
};
