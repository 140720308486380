"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.combinedActions = exports.digitalDataTransform = void 0;
var productActions_1 = require("../actions/productActions");
var productListActions_1 = require("../actions/productListActions");
var campaignActions_1 = require("../actions/campaignActions");
var contentActions_1 = require("../actions/contentActions");
var generalActions_1 = require("../actions/generalActions");
var quizActions_1 = require("../actions/quizActions");
var professionalActions_1 = require("../actions/professionalActions");
var accountDashboardAction_1 = require("../actions/accountDashboardAction");
var playgroundActions_1 = require("../actions/playgroundActions");
var paginationActions_1 = require("../actions/paginationActions");
var productRecommendationActions_1 = require("../actions/productRecommendationActions");
exports.digitalDataTransform = function (input, action, target, params) {
    var actions = exports.combinedActions[action];
    var dd = input;
    actions.forEach(function (a) {
        dd = a(dd, target, params);
    });
    return dd;
};
exports.combinedActions = __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, productActions_1.productActions), productListActions_1.productListActions), campaignActions_1.campaignActions), contentActions_1.contentActions), generalActions_1.generalActions), quizActions_1.quizActions), professionalActions_1.professionalActions), accountDashboardAction_1.accountDashboardAction), playgroundActions_1.playgroundActions), paginationActions_1.paginationActions), productRecommendationActions_1.productRecommendationActions);
