"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.logger = void 0;
var debugTools_1 = require("./debugTools");
exports.logger = {
    log: function () {
        var data = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            data[_i] = arguments[_i];
        }
    },
    warn: function () {
        var data = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            data[_i] = arguments[_i];
        }
    }
};
if (debugTools_1.isDebug()) {
    exports.logger.log = console.log.bind(window.console);
    exports.logger.warn = console.warn.bind(window.console);
}
