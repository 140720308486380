"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformActions = void 0;
var querySelector_1 = require("../helpers/querySelector");
var querySelector_2 = require("../helpers/querySelector");
var playgroundTransformActions_1 = require("./playgroundTransformActions");
var immutability_helper_1 = require("immutability-helper");
var logger_1 = require("../helpers/logger");
var selectorsProduct_1 = require("../consts/selectors/selectorsProduct");
exports.transformActions = __assign({ getPageInfo: function (input, target, params) {
        return input;
    }, getProductInfo: function (input, target, params) {
        var productTitle = querySelector_1.getInnerText(selectorsProduct_1.selectorsProduct.productTitle, ""), productVariant = getVariant(), productSku = getProductSku(productVariant);
        if (!productTitle) {
            productTitle = querySelector_1.getInnerText(selectorsProduct_1.selectorsProduct.productElucentTitle, "");
            var productDiv = document.querySelector('div[data-cmp-is="product"]');
            if (productDiv) {
                productSku = productDiv.getAttribute('data-product-sku');
                //console.log('Product SKU:', productSku);
            }
        }
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                eventCategory: { $set: "Product Viewed" },
                product: function (product) { return immutability_helper_1.default(product || {}, {
                    name: { $set: productTitle },
                    variant: { $set: productVariant },
                    sku: { $set: productSku }
                }); }
            }); }
        });
    }, getVariantInfo: function (input, target, params) {
        var variant = getVariant(target), sku = getProductSku(variant);
        return immutability_helper_1.default(input, {
            linkTracking: {
                product: {
                    variant: { $set: variant },
                    sku: { $set: sku }
                }
            }
        });
    }, getOnlineRetailerInfo: function (input, target, params) {
        var retailerObj = { name: getOnlineRetailerName(target), url: getOnlineRetailer(target) }, internalSearchObj = { searchTerm: getStoreSearchTerm() };
        return immutability_helper_1.default(input, {
            linkTracking: {
                internalSearch: { $set: internalSearchObj },
                product: {
                    retailer: { $set: retailerObj }
                }
            }
        });
    }, getFindAStoreInfo: function (input, target, params) {
        var retailerObj = getFindAStore(), internalSearchObj = { searchTerm: getStoreSearchTerm(), };
        return immutability_helper_1.default(input, {
            linkTracking: {
                internalSearch: { $set: internalSearchObj },
                product: {
                    retailer: { $set: retailerObj }
                }
            }
        });
    }, getFindAStoreInfoDirections: function (input, target, params) {
        var retailerObj = getFindAStore(), internalSearchObj = { searchTerm: getStoreSearchTerm(), };
        return immutability_helper_1.default(input, {
            linkTracking: {
                eventCategory: { $set: "Directions" },
                internalSearch: { $set: internalSearchObj },
                product: { retailer: { $set: retailerObj } }
            }
        });
    }, getFindAStoreInfoMapLocator: function (input, target, params) {
        var retailerObj = getFindAStore(), internalSearchObj = { searchTerm: getStoreSearchTerm(), };
        return immutability_helper_1.default(input, {
            linkTracking: {
                eventCategory: { $set: "Map icon" },
                internalSearch: { $set: internalSearchObj },
                product: {
                    retailer: { $set: retailerObj }
                }
            }
        });
    }, getFindAStoreInfoRetailerName: function (input, target, params) {
        var retailerObj = getFindAStore(), internalSearchObj = { searchTerm: getStoreSearchTerm(), };
        return immutability_helper_1.default(input, {
            linkTracking: {
                eventCategory: { $set: "Retailer Name" },
                internalSearch: { $set: internalSearchObj },
                product: {
                    retailer: { $set: retailerObj }
                }
            }
        });
    }, getLocationSearchChange: function (input, target, params) {
        var internalSearchObj = { searchTerm: getStoreSearchTerm(), };
        return immutability_helper_1.default(input, {
            linkTracking: {
                internalSearch: { $set: internalSearchObj }
            }
        });
    }, getAccordionInfo: function (input, target, params) {
        var accordionClass = getAccordionClass(target), accordionTitle = getAccordionTitle(target), accordionState = (accordionClass == "") ? accordionTitle + " expanded" : accordionTitle + " " + accordionClass;
        logger_1.logger.log("getAccordionInfo accordionClass=", accordionClass);
        return immutability_helper_1.default(input, {
            linkTracking: {
                product: {
                    info: { $set: accordionState }
                }
            }
        });
    }, getSocialIconInfo: function (input, target, params) {
        var socialShareIcon = target ? target.className.replace("social-share__btn social-share__btn--", "") : "";
        return immutability_helper_1.default(input, {
            linkTracking: {
                product: function (product) { return immutability_helper_1.default(product || {}, {
                    socialShare: { $set: socialShareIcon }
                }); }
            }
        });
    }, getReviewStarsInfo: function (input, target, params) {
        var r = { starsClicked: "starsClicked" };
        return immutability_helper_1.default(input, {
            linkTracking: {
                product: function (product) { return immutability_helper_1.default(product || {}, {
                    reviews: { $set: r }
                }); }
            }
        });
    }, getReviewLinkInfo: function (input, target, params) {
        var r = { linkClicked: "linkClicked" };
        return immutability_helper_1.default(input, {
            linkTracking: {
                product: function (product) { return immutability_helper_1.default(product || {}, {
                    reviews: { $set: r }
                }); }
            }
        });
    }, getReviewWriteLinkInfo: function (input, target, params) {
        var r = { linkClicked: "witeReviewClicked" };
        return immutability_helper_1.default(input, {
            linkTracking: {
                product: function (product) { return immutability_helper_1.default(product || {}, {
                    reviews: { $set: r }
                }); }
            }
        });
    }, reviewAddButtonClick: function (input, target, params) {
        var popupData = document.querySelector(".cmp-contentcontainer--popup.visible").getAttribute("data-bind-to-popup-trigger"), formEl = document.querySelector("#addreview"), name = formEl.parentElement.querySelector("h2").innerText, formElErrorList = document.querySelectorAll('.has-error'), event = (formElErrorList.length > 0) ? "started" : "completed";
        var errors = "";
        formElErrorList.forEach(function (e, i, t) {
            errors += e.querySelector('.help-block.form-error').innerText;
            if (i < (t.length - 1)) {
                errors += "|";
            }
        });
        return immutability_helper_1.default(input, {
            linkTracking: {
                linkText: { $set: "reviewSubmit" },
                popupName: { $set: popupData },
                forms: function (forms) { return immutability_helper_1.default(forms || {}, {
                    formName: { $set: name },
                    formError: { $set: errors },
                    formEvent: { $set: event }
                }); }
            }
        });
    }, getReviewMoreInfo: function (input, target, params) {
        var r = { moreClicked: "reviewMore" };
        return immutability_helper_1.default(input, {
            linkTracking: {
                product: {
                    reviews: { $set: r }
                }
            }
        });
    }, getProductCarouselInfo: function (input, target, params) {
        var productVariant = target.getAttribute(selectorsProduct_1.selectorsProduct.productVariantTitle), thumbImg = getProductThumbImage(target), productSku = getProductSku(productVariant), productIndex = parseInt(target.getAttribute('data-slide-to'));
        return immutability_helper_1.default(input, {
            linkTracking: {
                product: {
                    sku: { $set: productSku },
                    variant: { $set: productVariant },
                    thumbnailImage: { $set: thumbImg },
                    thumbnailIndex: { $set: productIndex }
                }
            }
        });
    }, getMEViewMapInfo: function (input, target, params) {
        var retailerObj = {
            name: target.parentElement.querySelector('.store-retailer-list__item-name').innerText,
            url: target.getAttribute('data-src-map-url')
        };
        return immutability_helper_1.default(input, {
            linkTracking: {
                product: function (product) { return immutability_helper_1.default(product || {}, {
                    retailer: { $set: retailerObj }
                }); }
            }
        });
    }, getWTBInfo: function (input, target, params) {
        var retailerObj = {
            name: target.parentElement.querySelector('.branch-title__link').innerText,
            url: target.getAttribute("href")
        };
        return immutability_helper_1.default(input, {
            linkTracking: {
                product: function (product) { return immutability_helper_1.default(product || {}, {
                    retailer: { $set: retailerObj }
                }); }
            }
        });
    }, MEWhereToBuyViewMapClick: function (input, target, params) {
    }, getHelpButtonInfo: function (input, target, params) {
        var reviewObj = {
            id: target.getAttribute("id"),
            dataReviewId: target.getAttribute('data-review-id'),
            btnText: target.innerText,
        };
        return immutability_helper_1.default(input, {
            linkTracking: {
                product: function (product) { return immutability_helper_1.default(product || {}, {
                    reviews: { $set: reviewObj }
                }); }
            }
        });
    }, getReviewCollapseInfo: function (input, target, params) {
        var r = { moreClicked: "reviewCollapseAll" };
        return immutability_helper_1.default(input, {
            linkTracking: {
                product: {
                    reviews: { $set: r }
                }
            }
        });
    }, 
    //dynamic link testing required. //This is the existing data layer function for the whole a link section. For the particular setup, the event will be differentiated below.
    getBuyOnlineLogoInfo: function (input, target, params) {
        var _a;
        var retailerObj = {
            name: target.getAttribute('data-tracking-id'),
            url: target.getAttribute("href")
        }, id = (_a = (target.getAttribute('data-tracking-id'))) !== null && _a !== void 0 ? _a : "NA", link = target.getAttribute('href'), imageBg = target.querySelector('img'), name = (imageBg !== null) ? imageBg.getAttribute('src').split("/")[imageBg.getAttribute('src').split("/").length - 1] : "None";
        return immutability_helper_1.default(input, {
            linkTracking: {
                linkText: { $set: id },
                linkDestinationURL: { $set: link },
                intentType: { $set: "Buy Now" },
                content: function (content) { return immutability_helper_1.default(content || {}, {
                    contentId: { $set: id },
                    contentName: { $set: name },
                    contentLink: { $set: link },
                    contentType: { $set: "buy online logo" },
                }); },
                product: {
                    retailer: { $set: retailerObj }
                }
            }
        });
    }, getWhereToBuyInfo: function (input, target, params) {
        var wrapper = (target.getAttribute('class').includes('image-wrapper')) ? target.parentElement : target.parentElement.parentElement, retailerObj = {
            name: wrapper.getAttribute('data-tracking-id'),
            url: wrapper.querySelector('div.button > button.cmp-form-button').getAttribute("data-button-href")
        }, 
        // id = (wrapper.getAttribute('data-tracking-id')) ?? "NA",
        id = (target.getAttribute('class').includes('image-wrapper')) ? (wrapper.getAttribute('data-tracking-id')) : "VISIT ONLINE", link = wrapper.querySelector('div.button > button.cmp-form-button').getAttribute("data-button-href"), imageSrc = wrapper.querySelector('div.image-bg.image-align-center').getAttribute('data-img-src');
        return immutability_helper_1.default(input, {
            linkTracking: {
                content: function (content) { return immutability_helper_1.default(content || {}, {
                    contentId: { $set: id },
                    contentName: { $set: imageSrc },
                    contentLink: { $set: link },
                    contentType: { $set: "buy online logo" },
                }); },
                linkDestinationURL: { $set: link },
                linkText: { $set: id },
                intentType: { $set: "Buy Now" },
                product: {
                    retailer: { $set: retailerObj }
                }
            }
        });
    }, getQty: function (input, target, params) {
        var product = target.parentElement.parentElement.parentElement.parentElement.parentElement, prodName = product.querySelector('h3.product-tile__item--title').getAttribute("data-prod-name"), linkText = prodName.toLowerCase(), linkDestinationURL = product.querySelector('.content-wrapper a').getAttribute("href"), sku = product.parentElement.parentElement.getAttribute('data-product-sku'), quantity = "1", imgLink = product.querySelector('.product-tile__item--image').getAttribute("data-thumbnail").split("'")[1];
        var tmp = immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                linkText: { $set: linkText },
                linkType: { $set: 'Add To Cart' },
                actionType: { $set: 'Add' },
                linkDestinationURL: { $set: linkDestinationURL },
                product: function (product) { return immutability_helper_1.default(product || {}, {
                    name: { $set: prodName },
                    sku: { $set: sku },
                    thumbnailImage: { $set: imgLink },
                    quantity: { $set: quantity }
                }); }
            }); }
        });
        return tmp;
    }, getProductPageQty: function (input, target, params) {
        //console.log(target)
        var product = target.parentElement.parentElement.parentElement, prodName = product.querySelector('.product-detail__content--title').textContent, sku = product.parentElement.getAttribute('data-product-sku');
        var selectElement = document.querySelector('select[name="quantity"]');
        var selectedValue = "1";
        if (selectElement) {
            selectedValue = selectElement.value;
        }
        var tmp = immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                linkType: { $set: 'Add To Cart' },
                actionType: { $set: 'Add' },
                eventCategory: { $set: "AddToCart" },
                product: function (product) { return immutability_helper_1.default(product || {}, {
                    name: { $set: prodName },
                    sku: { $set: sku },
                    quantity: { $set: selectedValue }
                }); }
            }); }
        });
        return tmp;
    }, getRemoveCart: function (input, target, params) {
        var tmp = immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                linkType: { $set: 'Remove Cart' },
                actionType: { $set: 'Delete' },
                product: function (product) { return immutability_helper_1.default(product || {}, {
                    name: { $set: params.productName },
                    quantity: { $set: params.qty }
                }); }
            }); }
        });
        return tmp;
    }, getEditCartDetails: function (input, target, params) {
        // Check if the class exists
        var cartBody = document.querySelector('.cmp-VeniaCartPage__cartPage__body');
        var quantity = "1";
        var productDetails = [];
        if (cartBody) {
            //console.log(cartBody)
            // Find all product items
            var productItems = cartBody.querySelectorAll('.cmp-VeniaProductListing__product__root');
            //console.log(productItems," ProductItems ")
            productItems.forEach(function (item) {
                var _a, _b;
                var title = (_a = item.querySelector('.cmp-VeniaProductListing__product__name')) === null || _a === void 0 ? void 0 : _a.textContent.trim();
                var price = (_b = item.querySelector('.cmp-VeniaProductListing__product__price')) === null || _b === void 0 ? void 0 : _b.textContent.trim();
                var quantityInput = item.querySelector('.cmp-VeniaProductListing__product__quantity input');
                quantity = (quantityInput === null || quantityInput === void 0 ? void 0 : quantityInput.value.trim()) || '';
                var product = {
                    name: title || undefined,
                    quantity: quantity,
                    price: price
                };
                productDetails.push(product);
            });
            //console.log(productDetails);
        }
        else {
            //console.log('Cart body class not found');
        }
        var tmp = immutability_helper_1.default(input, {
            editCart: function (editCart) { return immutability_helper_1.default(editCart || {}, {
                event: { $set: "Edit Cart Page" },
                products: { $set: productDetails }
            }); }
        });
        return tmp;
    }, getRemoveCartEditPage: function (input, target, params) {
        var product = target.parentElement;
        // Get the product name
        var productName = product.querySelector('.cmp-VeniaProductListing__product__name').textContent;
        // Get the product image URL
        var productImageURL = product.querySelector('.cmp-VeniaProductListing__product__image').src;
        // Get the quantity
        var quantity = product.querySelector('.cmp-VeniaProductListing__quantity__input').value;
        var price = product.querySelector('.cmp-VeniaProductListing__product__price').textContent;
        var tmp = immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                linkType: { $set: 'Remove From Cart' },
                actionType: { $set: 'Delete' },
                product: function (product) { return immutability_helper_1.default(product || {}, {
                    name: { $set: productName },
                    quantity: { $set: quantity },
                    price: { $set: price }
                }); }
            }); }
        });
        return tmp;
    }, getDiscountOnCheckout: function (input, target, params) {
        var tmp = immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                linkType: { $set: "Apply Discount" },
                actionType: { $set: 'Discount' },
                discountCode: { $set: params.code }
            }); }
        });
        return tmp;
    }, removeDiscountOnCheckout: function (input, target, params) {
        var tmp = immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                linkType: { $set: "Remove Discount" },
                actionType: { $set: 'Discount' },
                discountCode: { $set: params.code }
            }); }
        });
        return tmp;
    } }, playgroundTransformActions_1.playgroundTransformActions);
var getOnlineRetailer = function (target) { var _a; return (_a = target.getAttribute("href")) !== null && _a !== void 0 ? _a : ""; };
var getOnlineRetailerName = function (target) { var _a, _b; return (_b = (_a = target.parentElement) === null || _a === void 0 ? void 0 : _a.querySelector(selectorsProduct_1.selectorsProduct.RetailerName)) === null || _b === void 0 ? void 0 : _b.innerText; };
var getVariant = function (target) {
    return target ? target.innerText : querySelector_1.getInnerText(selectorsProduct_1.selectorsProduct.productVariant);
};
var getFindAStore = function () {
    var text = querySelector_1.getInnerText(selectorsProduct_1.selectorsProduct.shopDetails, "").replace('Get Directions', '');
    var obj = text.split("\n");
    var ret = {
        name: obj[0],
        address: obj[1],
        phone: obj[2],
        url: querySelector_1.getAttributeValue(selectorsProduct_1.selectorsProduct.shopUrl, "href")
    };
    logger_1.logger.log("getFindAStore text=", text, " ret=", ret);
    return ret;
};
var getStoreSearchTerm = function () {
    var oEl = querySelector_2.queryElement(selectorsProduct_1.selectorsProduct.locationFinderInput);
    return (oEl != null) ? oEl.value : "";
};
var getAccordionTitle = function (target) {
    var res = "";
    if (target) {
        res = target.innerText;
    }
    else {
        var oEl = (querySelector_2.queryElement(selectorsProduct_1.selectorsProduct.accordionProductInfoTitle));
        res = (oEl != null) ? oEl.parentNode.querySelector('button').innerText.split(" ")[0] : "";
    }
    return res;
};
var getAccordionClass = function (target) {
    var res = "";
    if (target) {
        res = target.classList;
    }
    else {
        var oEl = (querySelector_2.queryElement(selectorsProduct_1.selectorsProduct.accordionProductInfoTitle));
        res = (oEl != null) ? oEl.parentNode.querySelector('button').classList.toString() : "";
    }
    return res;
};
var getProductThumbImage = function (target) {
    var oEl = (target) ? target : querySelector_2.queryElement(selectorsProduct_1.selectorsProduct.ActiveThumbnailImage);
    return (oEl != null) ? oEl.style.backgroundImage : "";
};
var buildProductList = function () {
    var _a;
    var productsList = document.getElementsByTagName(selectorsProduct_1.selectorsProduct.productsListTagName), l = productsList.length, productSkuList = [];
    for (var i = 0; i < l; i++) {
        productSkuList.push({
            title: productsList[i].getAttribute(selectorsProduct_1.selectorsProduct.productsListTitleAttribute),
            sku: (_a = productsList[i].getAttribute(selectorsProduct_1.selectorsProduct.productsListSkuAttribute)) === null || _a === void 0 ? void 0 : _a.replace(" ", ""),
            path: productsList[i].getAttribute(selectorsProduct_1.selectorsProduct.productsListPagePathAttribute),
            image: productsList[i].getAttribute(selectorsProduct_1.selectorsProduct.productsListImageAttribute),
            thumbnail: productsList[i].getAttribute(selectorsProduct_1.selectorsProduct.productsListThumbnailAttribute),
        });
    }
    return productSkuList;
};
var getProductSku = function (variant) {
    var p = buildProductList();
    function checkSku(s) {
        logger_1.logger.log("sku=", s.sku, "variant=", variant);
        return s.title == variant;
    }
    var oEl = p.find(checkSku);
    return oEl === null || oEl === void 0 ? void 0 : oEl.sku;
};
