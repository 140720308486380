"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformActions = void 0;
var querySelector_1 = require("../helpers/querySelector");
var playgroundTransformActions_1 = require("./playgroundTransformActions");
var logger_1 = require("../helpers/logger");
var immutability_helper_1 = require("immutability-helper");
var $ = require("jquery");
require("jquery-validation");
exports.transformActions = __assign({ getPageInfo: function (input, target, params) {
        return input;
    }, getPopupInfo: function (input, target, params) {
        var name = getPopupName(target), title = getPopupTitle(target), fullTitle = (title != "undefined") ? title : "";
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                popupName: { $set: name },
                popupTitle: { $set: fullTitle }
            }); }
        });
    }, popupSubmitBtnClick: function (input, target, params) {
        $.validator.messages.required = $.validator.messages.email = '';
        var popupData = $(".cmp-contentcontainer--popup.visible").attr("data-bind-to-popup-trigger"), formEl = target.parentElement.parentElement.parentElement, name = $(formEl).attr("id"), isValid = $(formEl).valid(), event = (isValid) ? "completed" : "started", subscriptionValidator = $(formEl).validate({
            invalidHandler: function (event, validator) {
                $("label.error").each(function (i, e) {
                    // remove all label error messages 
                    $(e).remove();
                });
                //validator.resetForm();
            }
        }), formElErrorList = subscriptionValidator.errorList;
        var errors = "";
        logger_1.logger.log("popupSubmitBtnClick subscriptionValidator=", subscriptionValidator);
        formElErrorList.forEach(function (e, i, t) {
            errors += $(e.element).attr("current-error") + e.message;
            if (i < (t.length - 1)) {
                errors += "|";
            }
        });
        logger_1.logger.log("popupSubmitBtnClick errors = ", errors);
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                linkText: { $set: "subscribeSubmit" },
                popupName: { $set: popupData },
                forms: function (forms) { return immutability_helper_1.default(forms || {}, {
                    formName: { $set: name },
                    formError: { $set: errors },
                    formEvent: { $set: event }
                }); }
            }); }
        });
    }, popupCloseBtnClick: function (input, target, params) {
        var name = target.parentNode.parentNode.getAttribute("data-bind-to-popup-trigger");
        logger_1.logger.log("popupCloseBtnClick = ", name);
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                popupName: { $set: name }
            }); }
        });
    }, getImageCardInfo: function (input, target, params) {
        var _a;
        var id = (_a = (target.getAttribute('data-tracking-id'))) !== null && _a !== void 0 ? _a : "NA", link = target.getAttribute('href'), imageBg = target.querySelector('img'), name = (imageBg !== null) ? imageBg.getAttribute('src').split("/")[imageBg.getAttribute('src').split("/").length - 1] : "None";
        logger_1.logger.log('getImageCardInfo name=', name);
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                linkText: { $set: id },
                linkDestinationURL: { $set: link },
                content: function (content) { return immutability_helper_1.default(content || {}, {
                    contentId: { $set: id },
                    contentName: { $set: id },
                    contentLink: { $set: link },
                    contentType: { $set: "image card" },
                }); },
                product: function (product) { return immutability_helper_1.default(product || {}, {
                    retailer: function (retailer) { return immutability_helper_1.default(retailer || {}, {
                        name: { $set: name },
                    }); }
                }); }
            }); }
        });
    }, getVideoCardInfo: function (input, target, params) {
        var _a, _b;
        logger_1.logger.log("getVideoCardInfo target= ", target);
        var id = (_a = (target.getAttribute('data-tracking-id'))) !== null && _a !== void 0 ? _a : "NA", link = target.getAttribute('href'), h2 = (target.querySelector('h2') != null) ? target.querySelector('h2').innerText : null, h3 = (target.querySelector('h3') != null) ? target.querySelector('h3').innerText : null, contentName = (_b = (h2)) !== null && _b !== void 0 ? _b : h3;
        logger_1.logger.log("getVideoCardInfo = ", id, link, h2, h3, contentName);
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                linkText: { $set: contentName },
                linkDestinationURL: { $set: link },
                eventAction: { $set: "" },
                content: function (content) { return immutability_helper_1.default(content || {}, {
                    contentId: { $set: id },
                    contentName: { $set: contentName },
                    contentLink: { $set: link },
                    contentType: { $set: "video card" },
                }); }
            }); }
        });
    }, getHeroCtaInfo: function (input, target, params) {
        var _a;
        var id = (_a = (target.getAttribute('data-tracking-id'))) !== null && _a !== void 0 ? _a : "NA", text = target.innerText, link = target.getAttribute('href');
        logger_1.logger.log("getHeroCtaInfo = ", id, text, link);
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                linkText: { $set: text },
                linkDestinationURL: { $set: link },
                content: function (content) { return immutability_helper_1.default(content || {}, {
                    contentId: { $set: id },
                    contentLink: { $set: link },
                }); }
            }); }
        });
    }, getHeroBannerInfo: function (input, target, params) {
        var _a;
        var id = (_a = (target.getAttribute('data-tracking-id'))) !== null && _a !== void 0 ? _a : "NA", text = target.innerText, link = target.getAttribute('href');
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                linkText: { $set: text },
                linkDestinationURL: { $set: link },
                content: function (content) { return immutability_helper_1.default(content || {}, {
                    contentId: { $set: id },
                    contentLink: { $set: link },
                }); }
            }); }
        });
    }, getHeroBannerElucentInfo: function (input, target, params) {
        var _a;
        var id = (_a = (target.getAttribute('data-tracking-id'))) !== null && _a !== void 0 ? _a : "NA", text = target.innerText, link = target.getAttribute('href');
        var title = target.parentElement.querySelector('.hero-banner__text__title span').textContent;
        console.log(title);
        var subtitle = document.querySelector('.hero-banner__text__subtitle span p').textContent;
        console.log(subtitle);
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                linkText: { $set: text },
                linkDestinationURL: { $set: link },
                content: function (content) { return immutability_helper_1.default(content || {}, {
                    contentId: { $set: id },
                    contentLink: { $set: link },
                }); },
                banner: function (banner) { return immutability_helper_1.default(banner || {}, {
                    title: { $set: title },
                    subtitle: { $set: subtitle },
                }); }
            }); }
        });
    }, getVideoInfo: function (input, target, params) {
        var _a;
        var id = (_a = (target.getAttribute('data-tracking-id'))) !== null && _a !== void 0 ? _a : "NA", src = target.querySelector('img.image-el').getAttribute('src'), link = target.getAttribute('href');
        logger_1.logger.log("getVideoInfo = ", name);
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                linkDestinationURL: { $set: link },
                linkText: { $set: "" },
                eventAction: { $set: "" },
                content: function (content) { return immutability_helper_1.default(content || {}, {
                    contentId: { $set: id },
                    contentName: { $set: src },
                    contentLink: { $set: link },
                    contentType: { $set: "video" },
                }); }
            }); }
        });
    }, getReviewCardInfo: function (input, target, params) {
        var _a;
        var id = (_a = (target.getAttribute('data-tracking-id'))) !== null && _a !== void 0 ? _a : "NA", reviewContent = target.querySelector(".review-content").innerText, reviewPopupURL = $("#review-popup-content.visible a").attr("href");
        logger_1.logger.log("getReviewCardInfo = ", reviewContent, reviewPopupURL);
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                linkText: { $set: reviewContent },
                linkDestinationURL: { $set: reviewPopupURL },
                content: function (content) { return immutability_helper_1.default(content || {}, {
                    contentId: { $set: id },
                    contentName: { $set: reviewContent },
                    contentLink: { $set: reviewPopupURL },
                    contentType: { $set: "review" },
                }); }
            }); }
        });
    }, getInstagramInfo: function (input, target, params) {
        var _a;
        var id = (_a = (target.getAttribute('data-tracking-id'))) !== null && _a !== void 0 ? _a : "NA", src = target.querySelector('img').getAttribute('src'), href = target.getAttribute('href'), socialChannel = href.split(".")[1];
        ;
        logger_1.logger.log("getInstagramInfo = ", src, href);
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                socialChannel: { $set: socialChannel },
                linkDestinationURL: { $set: href },
                content: function (content) { return immutability_helper_1.default(content || {}, {
                    contentId: { $set: id },
                    contentName: { $set: src },
                    contentLink: { $set: href },
                    contentType: { $set: "instagram" },
                }); }
            }); }
        });
    }, getInstagramArrowInfo: function (input, target, params) {
        var direction = target.classList[1].replace("slick-", "");
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                eventAction: { $set: direction }
            }); }
        });
    }, getSocialShareInfo: function (input, target, params) {
        var _a;
        var id = (_a = (target.getAttribute('data-tracking-id'))) !== null && _a !== void 0 ? _a : "NA", src = target.querySelector('img').getAttribute('src'), href = target.getAttribute('href'), socialChannel = href.split(".")[1];
        logger_1.logger.log("getSocialShareInfo = ", src, href);
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                linkText: { $set: src },
                linkDestinationURL: { $set: href },
                socialChannel: { $set: socialChannel },
                content: function (content) { return immutability_helper_1.default(content || {}, {
                    contentId: { $set: id },
                    contentName: { $set: src },
                    contentLink: { $set: href },
                    contentType: { $set: "social-share" },
                }); }
            }); }
        });
    }, getEnquiryFormInfo: function (input, target, params) {
        var formEl = target.parentElement.parentElement.parentElement, name = $(formEl).attr("id"), isValid = $(formEl).valid(), event = (isValid) ? "completed" : "started", formElErrorList = $(formEl).validate().errorList;
        var errors = "";
        logger_1.logger.log("getEnquiryFormInfo formElErrorList=", formElErrorList, isValid);
        formElErrorList.forEach(function (e, i, t) {
            errors += $(e.element).attr("name") + " " + e.message;
            if (i < (t.length - 1)) {
                errors += "|";
            }
        });
        logger_1.logger.log("getEnquiryFormInfo errors = ", errors);
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                forms: function (forms) { return immutability_helper_1.default(forms || {}, {
                    formName: { $set: name },
                    formError: { $set: errors },
                    formEvent: { $set: event },
                }); }
            }); }
        });
    }, getYouTubeInfo: function (input, target, params) {
        logger_1.logger.log('*** getYouTubeInfo *** target=', target.getVideoData());
        var id = target.getVideoData().video_id, src = target.getVideoUrl(), data = target.getVideoData(), videoName = data.title;
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                eventAction: { $set: params.text },
                content: function (content) { return immutability_helper_1.default(content || {}, {
                    contentId: { $set: id },
                    contentName: { $set: videoName },
                    contentLink: { $set: src },
                    contentType: { $set: "YouTube video" },
                }); }
            }); }
        });
    }, getMeSliderInfo: function (input, target, params) {
        var _a;
        var id = (_a = (target.getAttribute('data-tracking-id'))) !== null && _a !== void 0 ? _a : "NA", name = (target.querySelector('h2').innerText !== "") ? target.querySelector('h2').innerText : target.querySelector('h3').innerText, href = target.getAttribute('href'), imagelink = target.querySelector('img').getAttribute("src");
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                linkText: { $set: name },
                linkDestinationURL: { $set: href },
                content: function (content) { return immutability_helper_1.default(content || {}, {
                    contentId: { $set: id },
                    contentName: { $set: name },
                    contentLink: { $set: imagelink },
                    contentType: { $set: "slider image" },
                }); }
            }); }
        });
    }, getImageLinkInfo: function (input, target, params) {
        var _a;
        var id = (_a = (target.getAttribute('data-tracking-id'))) !== null && _a !== void 0 ? _a : "NA", name = target.parentElement.getAttribute("data-title"), href = target.getAttribute('href'), imagelink = target.querySelector('img').getAttribute("src");
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                linkText: { $set: name },
                linkDestinationURL: { $set: href },
                content: function (content) { return immutability_helper_1.default(content || {}, {
                    contentId: { $set: id },
                    contentName: { $set: name },
                    contentLink: { $set: imagelink },
                    contentType: { $set: "image link" },
                }); }
            }); }
        });
    }, 
    //Dropdown Categories 
    getArticleDropdownInfo: function (input, target, params) {
        var tagName = target.getAttribute("data-tag-name");
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                linkText: { $set: tagName },
                linkType: { $set: 'Categories' },
            }); }
        });
    }, getSortDropdownInfo: function (input, target, params) {
        var tagName = target.getAttribute("data-sort-name");
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                linkText: { $set: tagName },
                linkType: { $set: 'Sort' },
            }); }
        });
    }, 
    // content tile hover 
    getContentTileReveal: function (input, target, params) {
        var linkText = (target.querySelector('div.title.align-center')) ?
            target.querySelector('div.title.align-center').getAttribute('data-content-title') : target.querySelector('div.title.align-end').getAttribute('data-content-title');
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                linkText: { $set: linkText },
                linkType: { $set: 'Articles' },
                actionType: { $set: 'Reveal' },
            }); }
        });
    }, 
    // content tile click 
    getContentTileClick: function (input, target, params) {
        var linkText = (target.querySelector('div.title.align-center')) ?
            target.querySelector('div.title.align-center').getAttribute('data-content-title') : target.querySelector('div.title.align-end').getAttribute('data-content-title');
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                linkText: { $set: linkText },
                linkType: { $set: 'Articles' },
                actionType: { $set: 'Reveal' },
            }); }
        });
    }, 
    // content tile cta
    getContentTileBtnClick: function (input, target, params) {
        // return target
        var contentTile = (target.querySelector('div.title.align-center')) ?
            target.querySelector('div.title.align-center').getAttribute('data-content-title') : target.querySelector('div.title.align-end').getAttribute('data-content-title'), linkDestinationURL = target.querySelector('a.content-wrapper').getAttribute('href'), linkText = target.querySelector('a.content-wrapper').getAttribute('data-link-name');
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                linkType: { $set: "" },
                actionType: { $set: "" },
                linkText: { $set: linkText },
                linkDestinationURL: { $set: linkDestinationURL },
                contentTile: { $set: contentTile },
            }); }
        });
    } }, playgroundTransformActions_1.playgroundTransformActions);
var getPopupName = function (target) {
    return target.getAttribute('data-bind-to-popup-trigger');
};
function getPopupTitle(target) {
    var _a, _b;
    return (_b = (_a = target.querySelector("div.cmp-contentcontainer--body > div > div > div > a")) === null || _a === void 0 ? void 0 : _a.innerHTML) !== null && _b !== void 0 ? _b : querySelector_1.getInnerText("a[href=\"#" + getPopupName(target) + "\"] > div.content > div > h2 > font");
}
