"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var digitalDataTransform_1 = require("../../digitalDataFabric/digitalDataTransform");
var pageHelpers = require("../../helpers/pageHelpers");
var initialState = {
    targetName: "pageLoad",
    digitalData: {
        page: {
            pageInfo: {
                pageName: pageHelpers.getTitle(),
                pageURL: pageHelpers.getUrl(),
                pageType: pageHelpers.getPageType(),
                previousPage: pageHelpers.getReferrer(),
                deviceType: pageHelpers.getDevice()
            }
        }
    }
};
function appReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case "PAGE_LOAD":
            {
                return Object.assign({}, state, {
                    targetName: action.action,
                    digitalData: __assign({}, digitalDataTransform_1.digitalDataTransform(state.digitalData, action.action, action.target, action.params))
                });
            }
        case "CLICK_BUTTON":
            {
                return Object.assign({}, state, {
                    targetName: action.action,
                    digitalData: __assign({}, digitalDataTransform_1.digitalDataTransform(state.digitalData, action.action, action.target, action.params))
                });
            }
        case "SUBMIT_FORM":
            {
                return Object.assign({}, state, {
                    activeLink: action.index,
                });
            }
        default:
            return state;
    }
}
exports.default = appReducer;
