"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformActions = void 0;
var playgroundTransformActions_1 = require("./playgroundTransformActions");
var logger_1 = require("../helpers/logger");
var immutability_helper_1 = require("immutability-helper");
var selectorsProduct_1 = require("../consts/selectors/selectorsProduct");
exports.transformActions = __assign({ getPageInfo: function (input, target, params) {
        return input;
    }, getProductFilters: function (input, target, params) {
        var plf = getProductListFilter(target);
        return immutability_helper_1.default(input, {
            productListFilter: { $set: plf }
        });
    }, getProductClick: function (input, target, params) {
        var product = target.parentElement.parentElement, prodName = target.getAttribute("data-tracking-id").replace("product-item-", ""), href = target.getAttribute("href"), sku = product.getAttribute("data-sku"), tags = product.getAttribute("tags");
        logger_1.logger.log('getProductClick prodName=', prodName);
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                product: function (product) { return immutability_helper_1.default(product || {}, {
                    name: { $set: prodName },
                    sku: { $set: sku },
                    tags: { $set: tags },
                }); }
            }); }
        });
    }, getProductItemReveal: function (input, target, params) {
        logger_1.logger.log('getProductItemReveal');
        var product = target, //li> div >common-product-item
        prodId = product.querySelector('div.content-wrapper > div.product-tile__links > a.product-tile__item--btn') && product.querySelector('div.content-wrapper > div.product-tile__links > a.product-tile__item--btn').getAttribute("data-tracking-id").replace("product-item-", ""), prodName = product.querySelector('h3.product-tile__item--title') && product.querySelector('h3.product-tile__item--title').getAttribute("data-prod-name"), linkText = prodName.toLowerCase(), linkType = product.querySelector('common-product-item') && product.querySelector('common-product-item').getAttribute("data-link-type"), //this shows reveal instead of Product Reveal // make the first letter capital
        sku = product.querySelector('common-product-item') && product.querySelector('common-product-item').getAttribute("data-sku"), tags = product.querySelector('common-product-item') && product.querySelector('common-product-item').getAttribute("tags"), imgLink = product.querySelector('div.product-reveal-card > div.product-reveal-card__face > div.product-tile__item--image') && product.querySelector('div.product-reveal-card > div.product-reveal-card__face > div.product-tile__item--image').getAttribute("data-thumbnail").split("'")[1];
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                linkText: { $set: linkText },
                linkType: { $set: 'Product' },
                actionType: { $set: linkType },
                product: function (product) { return immutability_helper_1.default(product || {}, {
                    id: { $set: prodId },
                    name: { $set: prodName },
                    sku: { $set: sku },
                    tags: { $set: tags },
                    thumbnailImage: { $set: imgLink }
                }); }
            }); }
        });
    }, getProductItemClick: function (input, target, params) {
        var product = target.parentElement.parentElement, //common-product-item
        prodId = product.querySelector('div.content-wrapper > div.product-tile__links > a.product-tile__item--btn').getAttribute("data-tracking-id").replace("product-item-", ""), prodName = product.querySelector('h3.product-tile__item--title').getAttribute("data-prod-name"), linkText = prodName.toLowerCase(), linkType = product.querySelector('common-product-item').getAttribute("data-link-type"), sku = product.querySelector('common-product-item').getAttribute("data-sku"), tags = product.querySelector('common-product-item').getAttribute("tags"), 
        // imgLink = product.querySelector('div.product-reveal-card__face > div.product-tile__item--image').style.backgroundImage.slice(4, -1).replace(/["']/g, "");
        imgLink = product.querySelector('div.product-reveal-card > div.product-reveal-card__face > div.product-tile__item--image').getAttribute("data-thumbnail").split("'")[1];
        logger_1.logger.log('getProductClick prodName=', prodId);
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                linkText: { $set: linkText },
                linkType: { $set: 'Product' },
                actionType: { $set: linkType },
                product: function (product) { return immutability_helper_1.default(product || {}, {
                    id: { $set: prodId },
                    name: { $set: prodName },
                    sku: { $set: sku },
                    tags: { $set: tags },
                    thumbnailImage: { $set: imgLink }
                }); }
            }); }
        });
    }, getProductItemDotClick: function (input, target, params) {
        var targetList = target.getAttribute("id"), listItems = document.querySelectorAll(selectorsProduct_1.selectorsProduct.carouselMobSlideLi);
        var carouselCard = {};
        listItems.forEach(function (lI) {
            (lI.getAttribute('aria-describedby') == targetList) ?
                carouselCard = {
                    prodName: lI.querySelector('common-product-item > div.product-reveal-card > div.product-reveal-card__face > h3').getAttribute('data-prod-name'),
                    prodId: lI.querySelector('common-product-item > div.product-reveal-card > div.product-reveal-card__face > h3').getAttribute('data-prod-name').toLowerCase(),
                    tags: lI.querySelector('common-product-item').getAttribute('tags'),
                    sku: lI.querySelector('common-product-item').getAttribute('data-sku'),
                    linkType: lI.querySelector('common-product-item').getAttribute('data-link-type'),
                    linkText: lI.querySelector('common-product-item > div.product-reveal-card > div.product-reveal-card__face > h3').getAttribute('data-prod-name'),
                    thumbnail: lI.querySelector('common-product-item > div.product-reveal-card > div.product-reveal-card__face > div.product-tile__item--image')
                        .getAttribute('data-thumbnail'),
                }
                : "";
        });
        return immutability_helper_1.default(input, {
            linkTracking: function (linkTracking) { return immutability_helper_1.default(linkTracking || {}, {
                linkText: { $set: carouselCard['linkText'] },
                linkType: { $set: 'Carousel' },
                product: function (product) { return immutability_helper_1.default(product || {}, {
                    id: { $set: carouselCard['prodId'] },
                    name: { $set: carouselCard['prodName'] },
                    sku: { $set: carouselCard['sku'] },
                    tags: { $set: carouselCard['tags'] },
                    thumbnailImage: { $set: carouselCard['thumbnail'] }
                }); }
            }); }
        });
    } }, playgroundTransformActions_1.playgroundTransformActions);
var getProductListFilter = function (target) {
    var res = [];
    var f = document.querySelectorAll('ul.filters__content--list-items > li.filter-active');
    f.forEach(function (element) {
        res.push(element.getAttribute('data-id'));
    });
    return res;
};
