"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.contentActions = void 0;
var contentTransformActions_1 = require("./contentTransformActions");
exports.contentActions = {
    popupVisible: [contentTransformActions_1.transformActions.getPageInfo, contentTransformActions_1.transformActions.getPopupInfo],
    popupSubmitBtnClick: [contentTransformActions_1.transformActions.getPageInfo, contentTransformActions_1.transformActions.popupSubmitBtnClick],
    popupCloseBtnClick: [contentTransformActions_1.transformActions.getPageInfo, contentTransformActions_1.transformActions.popupCloseBtnClick],
    contentCardClick: [contentTransformActions_1.transformActions.getPageInfo, contentTransformActions_1.transformActions.getImageCardInfo],
    videoCardClick: [contentTransformActions_1.transformActions.getPageInfo, contentTransformActions_1.transformActions.getVideoCardInfo],
    heroCarouselCtaClick: [contentTransformActions_1.transformActions.getPageInfo, contentTransformActions_1.transformActions.getHeroCtaInfo],
    heroBannerClick: [contentTransformActions_1.transformActions.getPageInfo, contentTransformActions_1.transformActions.getHeroBannerInfo],
    videoPosterClick: [contentTransformActions_1.transformActions.getPageInfo, contentTransformActions_1.transformActions.getVideoInfo],
    reviewCardClick: [contentTransformActions_1.transformActions.getPageInfo, contentTransformActions_1.transformActions.getReviewCardInfo],
    instagramClick: [contentTransformActions_1.transformActions.getPageInfo, contentTransformActions_1.transformActions.getInstagramInfo],
    instagramArrowClick: [contentTransformActions_1.transformActions.getPageInfo, contentTransformActions_1.transformActions.getInstagramArrowInfo],
    socialShareClick: [contentTransformActions_1.transformActions.getPageInfo, contentTransformActions_1.transformActions.getSocialShareInfo],
    enquiryFormSubmitClick: [contentTransformActions_1.transformActions.getPageInfo, contentTransformActions_1.transformActions.getEnquiryFormInfo],
    youtubeEventFired: [contentTransformActions_1.transformActions.getPageInfo, contentTransformActions_1.transformActions.getYouTubeInfo],
    MEDiscoverSlideClick: [contentTransformActions_1.transformActions.getPageInfo, contentTransformActions_1.transformActions.getMeSliderInfo],
    imageLinkClick: [contentTransformActions_1.transformActions.getPageInfo, contentTransformActions_1.transformActions.getImageLinkInfo],
    dropdownOnArticleClick: [contentTransformActions_1.transformActions.getPageInfo, contentTransformActions_1.transformActions.getArticleDropdownInfo],
    dropdownOnSortClick: [contentTransformActions_1.transformActions.getPageInfo, contentTransformActions_1.transformActions.getSortDropdownInfo],
    contentTileReveal: [contentTransformActions_1.transformActions.getPageInfo, contentTransformActions_1.transformActions.getContentTileReveal],
    contentTileBtnClick: [contentTransformActions_1.transformActions.getPageInfo, contentTransformActions_1.transformActions.getContentTileBtnClick],
    contentTileClick: [contentTransformActions_1.transformActions.getPageInfo, contentTransformActions_1.transformActions.getContentTileClick],
    heroBannerClickElucent: [contentTransformActions_1.transformActions.getPageInfo, contentTransformActions_1.transformActions.getHeroBannerElucentInfo],
};
