"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.digitalData = exports.store = void 0;
var store_1 = require("./state/store");
var logger_1 = require("./helpers/logger");
exports.store = store_1.default;
exports.digitalData = {};
Object.assign(exports.digitalData, exports.store.getState().app.digitalData);
// document.dispatchEvent(new Event("pageLoad"));
exports.store.subscribe(function () {
    Object.assign(exports.digitalData, exports.store.getState().app.digitalData);
    if (exports.store.getState().app.targetName) {
        document.dispatchEvent(new Event(exports.store.getState().app.targetName));
        logger_1.logger.log("he:event:" + exports.store.getState().app.targetName, exports.digitalData, JSON.stringify(exports.digitalData));
    }
});
