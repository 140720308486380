"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.productListActions = void 0;
var productListTransformActions_1 = require("./productListTransformActions");
exports.productListActions = {
    pageLoad: [productListTransformActions_1.transformActions.getPageInfo],
    productListFilterClick: [productListTransformActions_1.transformActions.getPageInfo, productListTransformActions_1.transformActions.getProductFilters],
    productListItemClick: [productListTransformActions_1.transformActions.getPageInfo, productListTransformActions_1.transformActions.getProductClick],
    productItemReveal: [productListTransformActions_1.transformActions.getPageInfo, productListTransformActions_1.transformActions.getProductItemReveal],
    productItemClick: [productListTransformActions_1.transformActions.getPageInfo, productListTransformActions_1.transformActions.getProductItemClick],
    productSlickDotClick: [productListTransformActions_1.transformActions.getPageInfo, productListTransformActions_1.transformActions.getProductItemDotClick],
};
