"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseQuery = exports.mergeArrays = exports.getCookie = exports.getDevice = exports.getReferrer = exports.getPageType = exports.getUrl = exports.getTitle = void 0;
exports.getTitle = function () { return document.title; };
exports.getUrl = function () { return document.location.href; };
exports.getPageType = function () { return "normal"; };
exports.getReferrer = function () { return document.referrer; };
exports.getDevice = function () { return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ? "mobile" : "desktop"; };
exports.getCookie = function (cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};
exports.mergeArrays = function () {
    var arrays = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        arrays[_i] = arguments[_i];
    }
    var jointArray = [];
    arrays.forEach(function (array) {
        jointArray = __spreadArrays(jointArray, array);
    });
    var uniqueArray = jointArray.reduce(function (newArray, item) {
        if (newArray.includes(item)) {
            return newArray;
        }
        else {
            return __spreadArrays(newArray, [item]);
        }
    }, []);
    return uniqueArray;
};
exports.parseQuery = function (queryString) {
    var query = {};
    var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
};
