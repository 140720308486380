"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.professionalActions = void 0;
var professionalTransformActions_1 = require("./professionalTransformActions");
exports.professionalActions = {
    pageLoad: [professionalTransformActions_1.transformActions.getPageInfo],
    productGuideDownload: [professionalTransformActions_1.transformActions.getPageInfo, professionalTransformActions_1.transformActions.getProductGuideInfo],
    pdfDownloadClick: [professionalTransformActions_1.transformActions.getPageInfo, professionalTransformActions_1.transformActions.getPdfInfo],
    updateButtonClick: [professionalTransformActions_1.transformActions.getPageInfo, professionalTransformActions_1.transformActions.updateFormSuccess]
};
