"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.productActions = void 0;
var productTransformActions_1 = require("./productTransformActions");
exports.productActions = {
    pageLoad: [productTransformActions_1.transformActions.getPageInfo, productTransformActions_1.transformActions.getProductInfo, productTransformActions_1.transformActions.getVariantInfo, productTransformActions_1.transformActions.getFindAStoreInfo],
    buyOnlineButtonClick: [productTransformActions_1.transformActions.getPageInfo, productTransformActions_1.transformActions.getProductInfo],
    buyNowButtonClick: [productTransformActions_1.transformActions.getPageInfo, productTransformActions_1.transformActions.getProductInfo, productTransformActions_1.transformActions.getOnlineRetailerInfo],
    findAStoreButtonClick: [productTransformActions_1.transformActions.getPageInfo, productTransformActions_1.transformActions.getProductInfo, productTransformActions_1.transformActions.getFindAStoreInfo],
    variantButtonClick: [productTransformActions_1.transformActions.getPageInfo, productTransformActions_1.transformActions.getProductInfo, productTransformActions_1.transformActions.getVariantInfo],
    mapLocatorButtonClick: [productTransformActions_1.transformActions.getPageInfo, productTransformActions_1.transformActions.getProductInfo, productTransformActions_1.transformActions.getFindAStoreInfoMapLocator],
    locationSearchChange: [productTransformActions_1.transformActions.getPageInfo, productTransformActions_1.transformActions.getProductInfo, productTransformActions_1.transformActions.getLocationSearchChange],
    accordionButtonClick: [productTransformActions_1.transformActions.getPageInfo, productTransformActions_1.transformActions.getProductInfo, productTransformActions_1.transformActions.getAccordionInfo],
    socialIconClick: [productTransformActions_1.transformActions.getPageInfo, productTransformActions_1.transformActions.getProductInfo, productTransformActions_1.transformActions.getSocialIconInfo],
    reviewStarsClick: [productTransformActions_1.transformActions.getPageInfo, productTransformActions_1.transformActions.getProductInfo, productTransformActions_1.transformActions.getReviewStarsInfo],
    reviewLinkClick: [productTransformActions_1.transformActions.getPageInfo, productTransformActions_1.transformActions.getProductInfo, productTransformActions_1.transformActions.getReviewLinkInfo],
    reviewWriteLinkClick: [productTransformActions_1.transformActions.getPageInfo, productTransformActions_1.transformActions.getProductInfo, productTransformActions_1.transformActions.getReviewWriteLinkInfo],
    reviewAddButtonClick: [productTransformActions_1.transformActions.getPageInfo, productTransformActions_1.transformActions.getProductInfo, productTransformActions_1.transformActions.reviewAddButtonClick],
    reviewMoreClick: [productTransformActions_1.transformActions.getPageInfo, productTransformActions_1.transformActions.getProductInfo, productTransformActions_1.transformActions.getReviewMoreInfo],
    reviewCollapseClick: [productTransformActions_1.transformActions.getPageInfo, productTransformActions_1.transformActions.getProductInfo, productTransformActions_1.transformActions.getReviewCollapseInfo],
    productCarouselClick: [productTransformActions_1.transformActions.getPageInfo, productTransformActions_1.transformActions.getProductInfo, productTransformActions_1.transformActions.getProductCarouselInfo],
    MEbuyOnlineButtonClick: [productTransformActions_1.transformActions.getPageInfo, productTransformActions_1.transformActions.getProductInfo],
    MEfindAStoreButtonClick: [productTransformActions_1.transformActions.getPageInfo, productTransformActions_1.transformActions.getProductInfo, productTransformActions_1.transformActions.getFindAStoreInfo],
    MEViewMapClick: [productTransformActions_1.transformActions.getPageInfo, productTransformActions_1.transformActions.getProductInfo, productTransformActions_1.transformActions.getMEViewMapInfo],
    MEWhereToBuyRetailerNameClick: [productTransformActions_1.transformActions.getPageInfo, productTransformActions_1.transformActions.getProductInfo, productTransformActions_1.transformActions.getWTBInfo],
    MEWhereToBuyBuyNowClick: [productTransformActions_1.transformActions.getPageInfo, productTransformActions_1.transformActions.getProductInfo, productTransformActions_1.transformActions.getWTBInfo],
    MEWhereToBuyViewMapClick: [productTransformActions_1.transformActions.getPageInfo, productTransformActions_1.transformActions.getProductInfo, productTransformActions_1.transformActions.getWTBInfo],
    getFindAStoreDirectionsClick: [productTransformActions_1.transformActions.getPageInfo, productTransformActions_1.transformActions.getProductInfo, productTransformActions_1.transformActions.getFindAStoreInfoDirections],
    getFindAStoreRetailerNameClick: [productTransformActions_1.transformActions.getPageInfo, productTransformActions_1.transformActions.getProductInfo, productTransformActions_1.transformActions.getFindAStoreInfoRetailerName],
    reviewHelpButtonClick: [productTransformActions_1.transformActions.getPageInfo, productTransformActions_1.transformActions.getProductInfo, productTransformActions_1.transformActions.getHelpButtonInfo],
    buyOnlineLogoClick: [productTransformActions_1.transformActions.getPageInfo, productTransformActions_1.transformActions.getProductInfo, productTransformActions_1.transformActions.getBuyOnlineLogoInfo],
    whereToBuyClick: [productTransformActions_1.transformActions.getPageInfo, productTransformActions_1.transformActions.getProductInfo, productTransformActions_1.transformActions.getWhereToBuyInfo],
    addToCart: [productTransformActions_1.transformActions.getPageInfo, productTransformActions_1.transformActions.getProductInfo, productTransformActions_1.transformActions.getQty],
    removeCart: [productTransformActions_1.transformActions.getPageInfo, productTransformActions_1.transformActions.getProductInfo, productTransformActions_1.transformActions.getRemoveCart],
    elucentProductInformation: [productTransformActions_1.transformActions.getProductInfo],
    addToCartPage: [productTransformActions_1.transformActions.getPageInfo, productTransformActions_1.transformActions.getProductInfo, productTransformActions_1.transformActions.getProductPageQty],
    editCartPage: [productTransformActions_1.transformActions.getPageInfo, productTransformActions_1.transformActions.getEditCartDetails],
    removeEditCartPage: [productTransformActions_1.transformActions.getRemoveCartEditPage, productTransformActions_1.transformActions.getEditCartDetails],
    getDiscountOnCheckout: [productTransformActions_1.transformActions.getDiscountOnCheckout],
    removeDiscountOnCheckout: [productTransformActions_1.transformActions.removeDiscountOnCheckout]
};
