"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.accountDashboardAction = void 0;
var accountDashboardTransformAction_1 = require("./accountDashboardTransformAction");
exports.accountDashboardAction = {
    accountInformationClick: [accountDashboardTransformAction_1.transformActions.getPageInfo, accountDashboardTransformAction_1.transformActions.getAccountInfo],
    newslettersClick: [accountDashboardTransformAction_1.transformActions.getPageInfo],
    manageAddressesClick: [accountDashboardTransformAction_1.transformActions.getPageInfo, accountDashboardTransformAction_1.transformActions.getManageAddressInfo],
    addressBookEditClick: [accountDashboardTransformAction_1.transformActions.getPageInfo, accountDashboardTransformAction_1.transformActions.getEditAddressInfo],
    addressBookDeleteClick: [accountDashboardTransformAction_1.transformActions.getPageInfo, accountDashboardTransformAction_1.transformActions.getDeleteAddressInfo]
};
